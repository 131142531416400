import * as React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import '../../styles/management.scss';

const RyanDeberry = () => {
  return (
    <Layout>
      <SEO
        title="RYAN DEBERRY - Management | Ostara"
        description="NUTRIENT MANAGEMENT SOLUTIONS"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main className="management">
        <Container
          className="management-details-container"
          w="90%"
          paddingBottom={30}
          maxW={1694}
        >
          <Box className="manager-details">
            <div className="manager-details__text">
              <Link className="manager-details__back" to="/management">
                <ArrowBackIcon color="#044606" />
                BACK
              </Link>
              <StaticImage
                className="manager-details__img mobile"
                placeholder="transparent"
                src="../../images/ryan-deberry.jpg"
                alt=""
              />
              <Heading
                as="h2"
                color="#044606"
                className="manager-details__name"
              >
                Ryan DeBerry
              </Heading>
              <Text color="#044606" className="manager-details__position">
                Chief Operations Officer
              </Text>
              <Text color="#044606" className="manager-details__bio">
                Mr. DeBerry has more than 20 years of experience managing and
                helping to increase efficiencies at large operations. Before
                arriving at Ostara in 2013, he was General Manager at the
                Fairmont facility at Resolute Forest Products for eight years.
                Prior to that, he ran his own gas and oil drilling services
                business for four years, DeBerry Enterprises, Inc. Mr. DeBerry
                is a graduate of Fairmont State College with a Bachelor of
                Science in Safety Engineering Technology.
              </Text>
            </div>
            <StaticImage
              className="manager-details__img desktop"
              placeholder="transparent"
              src="../../images/ryan-deberry.jpg"
              alt=""
            />
          </Box>
        </Container>

        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default RyanDeberry;
